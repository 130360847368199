import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

const ContactForm: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const navigate = useNavigate();

  const validateInput = (name: string, value: string) => {
    // Remove any potential script or link tags
    const sanitizedValue = value.replace(/<(script|a).*?>/gi, "");

    switch (name) {
      case "user_name":
        if (!/^[a-zA-Z\s]{2,50}$/.test(sanitizedValue)) {
          return "Name must be 2-50 letters only";
        }
        break;

      case "user_email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(sanitizedValue)) {
          return "Please enter a valid email address";
        }
        break;

      case "requirement":
        if (sanitizedValue.length < 10 || sanitizedValue.length > 500) {
          return "Project details must be between 10-500 characters";
        }
        break;

      case "budget":
        if (value && !/^\d{1,10}$/.test(sanitizedValue)) {
          return "Budget must be a number";
        }
        break;
    }

    return "";
  };

  const handleInputValidation = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const error = validateInput(name, value);

    setValidationErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate all fields before submission
    const formElements = form.current?.elements;
    const errors: { [key: string]: string } = {};

    if (formElements) {
      Array.from(formElements).forEach((elem) => {
        if (
          elem instanceof HTMLInputElement ||
          elem instanceof HTMLTextAreaElement
        ) {
          const error = validateInput(elem.name, elem.value);
          if (error) {
            errors[elem.name] = error;
          }
        }
      });
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setIsLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID ?? "",
        "template_contact",
        form.current!,
        process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY ?? ""
      )
      .then(
        () => {
          setIsLoading(false);
          setShowSuccessModal(true);
        },
        (error) => {
          console.error("Failed to send message:", error.text);
          setIsLoading(false);
          alert("Failed to send message. Please try again.");
        }
      );
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate("/");
  };

  return (
    <>
      <div className="min-h-screen flex items-center justify-center px-4 py-12 my-3 md:my-0 bg-gradient-to-br from-[#070508] via-[#080609] to-[#09090b]">
        <div className="w-full my-4 max-w-4xl border-2 border-[#1cd5ff] rounded-xl overflow-hidden shadow-2xl transform transition-all duration-300 hover:scale-[1.02]">
          <div className="grid md:grid-cols-2">
            {/* Left Side Content */}
            <div className="bg-[#016e86] text-white flex flex-col justify-center p-6 space-y-2">
              <h1 className="text-3xl font-bold mb-4 text-center">
                Let's Create Something Amazing
              </h1>
              <p className="text-lg text-center italic opacity-80">
                "Turning ideas into digital experiences"
              </p>
              <p className="text-md text-center">
                I'm excited to hear about your project and explore how we can
                bring it to life together.
              </p>
            </div>

            {/* Form Section */}
            <div className="bg-[#1a1a1d] text-gray-200 p-6 space-y-2">
              <h2 className="text-2xl font-bold mb-6 text-center text-white">
                Get in Touch
              </h2>

              <form ref={form} onSubmit={sendEmail} className="space-y-2">
                {/* Name Input */}
                <div className="relative">
                  <input
                    type="text"
                    name="user_name"
                    required
                    placeholder=" "
                    onBlur={handleInputValidation}
                    className={`peer w-full px-3 py-2 bg-transparent border-b-2 ${
                      validationErrors.user_name
                        ? "border-red-500"
                        : "border-gray-600 focus:border-[#1cd5ff]"
                    } text-gray-200 
                    focus:outline-none transition-colors duration-300 
                    pt-4 pb-1`}
                  />
                  <label
                    className="absolute left-0 -top-3.5 text-gray-400 text-sm 
                    peer-placeholder-shown:text-base peer-placeholder-shown:top-4 
                    peer-focus:-top-3.5 peer-focus:text-sm 
                    peer-focus:text-[#1cd5ff] 
                    transition-all duration-300 
                    pointer-events-none"
                  >
                    Name
                  </label>
                  {validationErrors.user_name && (
                    <p className="text-red-500 text-xs my-1 text-left">
                      {validationErrors.user_name}
                    </p>
                  )}
                </div>

                {/* Email Input */}
                <div className="relative">
                  <input
                    type="email"
                    name="user_email"
                    required
                    placeholder=" "
                    onBlur={handleInputValidation}
                    className={`peer w-full px-3 py-2 bg-transparent border-b-2 ${
                      validationErrors.user_email
                        ? "border-red-500"
                        : "border-gray-600 focus:border-[#1cd5ff]"
                    } text-gray-200 
                    focus:outline-none transition-colors duration-300 
                    pt-4 pb-1`}
                  />
                  <label
                    className="absolute left-0 -top-3.5 text-gray-400 text-sm 
                    peer-placeholder-shown:text-base peer-placeholder-shown:top-4 
                    peer-focus:-top-3.5 peer-focus:text-sm 
                    peer-focus:text-[#1cd5ff] 
                    transition-all duration-300 
                    pointer-events-none"
                  >
                    Email
                  </label>
                  {validationErrors.user_email && (
                    <p className="text-red-500 text-xs my-1 text-left">
                      {validationErrors.user_email}
                    </p>
                  )}
                </div>

                {/* Project Details Textarea */}
                <div className="relative">
                  <textarea
                    name="requirement"
                    required
                    rows={4}
                    placeholder=" "
                    onBlur={handleInputValidation}
                    className={`peer w-full px-3 py-2 bg-transparent border-b-2 ${
                      validationErrors.requirement
                        ? "border-red-500"
                        : "border-gray-600 focus:border-[#1cd5ff]"
                    } text-gray-200 
                    focus:outline-none transition-colors duration-300 
                    resize-none
                    pt-4 pb-1`}
                  ></textarea>
                  <label
                    className="absolute left-0 -top-3.5 text-gray-400 text-sm 
                    peer-placeholder-shown:text-base peer-placeholder-shown:top-4 
                    peer-focus:-top-3.5 peer-focus:text-sm 
                    peer-focus:text-[#1cd5ff] 
                    transition-all duration-300 
                    pointer-events-none"
                  >
                    Project Details
                  </label>
                  {validationErrors.requirement && (
                    <p className="text-red-500 text-xs my-1 text-left">
                      {validationErrors.requirement}
                    </p>
                  )}
                </div>

                {/* Budget Input */}
                <div className="relative">
                  <input
                    type="text"
                    name="budget"
                    placeholder=" "
                    onBlur={handleInputValidation}
                    className={`peer w-full px-3 py-2 bg-transparent border-b-2 ${
                      validationErrors.budget
                        ? "border-red-500"
                        : "border-gray-600 focus:border-[#1cd5ff]"
                    } text-gray-200 
                    focus:outline-none transition-colors duration-300 
                    pt-4 pb-1`}
                  />
                  <label
                    className="absolute left-0 -top-3.5 text-gray-400 text-sm 
                    peer-placeholder-shown:text-base peer-placeholder-shown:top-4 
                    peer-focus:-top-3.5 peer-focus:text-sm 
                    peer-focus:text-[#1cd5ff] 
                    transition-all duration-300 
                    pointer-events-none"
                  >
                    Budget (Optional)
                  </label>
                  {validationErrors.budget && (
                    <p className="text-red-500 text-xs my-1 text-left">
                      {validationErrors.budget}
                    </p>
                  )}
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full bg-[#1cd5ff] text-black py-3 px-4 rounded-md shadow-md 
                  hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-[#1cd5ff] 
                  focus:ring-offset-2 transition duration-300 transform hover:scale-[1.02] 
                  flex items-center justify-center relative"
                >
                  {isLoading ? (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-6 h-6 border-4 border-black border-t-transparent border-r-transparent rounded-full animate-spin"></div>
                    </div>
                  ) : (
                    "Send Project Inquiry"
                  )}
                  {isLoading && (
                    <span className="opacity-0">Send Project Inquiry</span>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#1a1a1d] p-8 rounded-lg shadow-xl text-center max-w-md w-full border border-[#1cd5ff]">
            <h2 className="text-2xl font-bold mb-4 text-[#1cd5ff]">
              Message Sent Successfully!
            </h2>
            <p className="mb-6 text-gray-300">
              Thank you for reaching out. I'll get back to you soon.
            </p>
            <button
              onClick={handleModalClose}
              className="bg-[#1cd5ff] text-black py-2 px-6 rounded-md hover:bg-opacity-90 transition duration-300"
            >
              Okay
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactForm;
